import React, { useEffect } from "react";
import styles from "./Pdf.module.css";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { SpecialZoomLevel } from "@react-pdf-viewer/core";

export const Pdf = ({
  pdfUrl,
  searchPluginInstance,
  onSearchPluginInstance,
}) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  // Get the search function from the plugin
  const { Search } = searchPluginInstance;

  useEffect(() => {
    // When the search component is rendered, pass its instance up to the parent
    if (Search && onSearchPluginInstance) {
      onSearchPluginInstance(Search);
    }
  }, [Search, onSearchPluginInstance]);

  const transform = (slot) => {
    const { NumberOfPages } = slot;
    return Object.assign({}, slot, {
      ...slot,
      NumberOfPages: () => (
        <>
          / <NumberOfPages />
        </>
      ),
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
      Open: () => <></>,
    });
  };

  const renderToolbar = () => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
  });

  return (
    <div className={styles.pdfContainer}>
      <Viewer
        fileUrl={pdfUrl}
        plugins={[
          toolbarPluginInstance,
          defaultLayoutPluginInstance,
          searchPluginInstance,
        ]}
        defaultScale={SpecialZoomLevel.PageWidth}
      />
    </div>
  );
};
