import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Auth.module.css";

export const WebsiteOnboarding = () => {
  const navigate = useNavigate();
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const handleContinue = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Navigate to create-organization-url with the website URL as state
      navigate("/create-organization-url", {
        state: { websiteUrl },
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = () => {
    navigate("/create-organization");
  };

  return (
    <>
      <div className={styles.whiteContainer}>
        <div className={styles.whiteCard}>
          <h1 className={styles.title}>Onboarding...</h1>
          <form className={styles.form} onSubmit={handleContinue}>
            <div className={styles.inputWithButtons}>
              <input
                type="url"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                placeholder="Enter your website URL"
                required
              />
              <div className={styles.inputButtons}>
                <button
                  type="button"
                  onClick={handleSkip}
                  className={styles.inputButton}
                >
                  Skip
                </button>
                <button
                  type="submit"
                  className={`${styles.inputButton} ${
                    styles.primaryInputButton
                  } ${loading ? styles.loading : ""}`}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Continue"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
