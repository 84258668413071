import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Landing } from "./pages/Landing/Landing.page";
import { Bills } from "./pages/Bills/Bills.page";
import { BillChat } from "./pages/BillChat/BillChat.page";
import { Auth } from "./pages/Forms/Auth/Auth.page";
import { Login } from "./pages/Forms/Login/Login.page";
import { ForgotPassword } from "./pages/Forms/ForgotPassword/ForgotPassword.page";
import { CreateOrganization } from "./pages/Forms/CreateOrganization/CreateOrganization.page";
import { WebsiteOnboarding } from "./pages/Forms/WebsiteOnboarding/WebsiteOnboarding.page";
import { CreateOrganizationUrl } from "./pages/Forms/CreateOrganizationUrl/CreateOrganizationUrl.page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/bills",
    element: <Bills />,
  },
  {
    path: "/billChat",
    element: <BillChat />,
  },
  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/create-organization",
    element: <CreateOrganization />,
  },
  {
    path: "/website-onboarding",
    element: <WebsiteOnboarding />,
  },
  {
    path: "/create-organization-url",
    element: <CreateOrganizationUrl />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
