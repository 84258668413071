import React from "react";
import styles from "./Bills.module.css";
import { BillRow } from "../../components/BillRow/BillRow.component";
import { IoArrowDownSharp } from "react-icons/io5";

const testData = [
  {
    impact: 0.7,
    id: "S743",
    title: "The Disaster Recovery Act of 2024 - Part II.",
    summary:
      "The legislation modifies the Disaster Recovery Act of 2024 by allocating over $600 million for various disaster recovery efforts, including education, health, infrastructure, and economic relief, specifically targeting areas affected by Hurricane Helene.",
    lastActionDate: "2024-07-01",
    lastAction: "Ch. SL 2024-53",
  },
  {
    impact: 0.7,
    id: "S743",
    title: "The Disaster Recovery Act of 2024 - Part II.",
    summary:
      "The legislation modifies the Disaster Recovery Act of 2024 by allocating over $600 million for various disaster recovery efforts, including education, health, infrastructure, and economic relief, specifically targeting areas affected by Hurricane Helene.",
    lastActionDate: "2024-07-01",
    lastAction: "Ch. SL 2024-53",
  },
];

const tableColumns = ["Imapct", "ID", "Title", "Summary", "Last Action"];

export const Bills = () => {
  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search bills..."
          className={styles.search}
        />
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.upperRow}>
    


          <div className={styles.impact}>
            <p>Impact</p> <IoArrowDownSharp />
          </div>
          <div className={styles.id}>
            <p>ID</p> <IoArrowDownSharp />
          </div>
          <div className={styles.title}>
            <p>Title</p> <IoArrowDownSharp />
          </div>
          <div className={styles.summary}>
            <p>Summary</p> <IoArrowDownSharp />
          </div>
          <div className={styles.lastActionDate}>
            <p>Last Action</p> <IoArrowDownSharp />
          </div>
        </div>
        {testData.map((bill, index) => (
          <BillRow bill={bill} key={index} />
        ))}
      </div>
    </div>
  );
};
