import axios from "axios";
import { endpoint, backendEndpoint } from "../data/endpoint.data";

export const getDemo = async (url) => {
  try {
    console.log("Run");
    const res = await axios.get(`${endpoint}/demo/url_to_bills`, {
      params: { url },
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { number, summary, splits } = res.data;
    console.log("data", res.data);
    const billData = await axios.post(`${endpoint}/bills/bill`, {
      billId: number,
    });
    console.log(billData.data);
    const { title, updateDate } = billData.data.bill;

    return {
      number,
      summary,
      title,
      splits,
      updateDate,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};
