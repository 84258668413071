import React, { useState, useRef, useEffect } from "react";
import styles from "./Landing.module.css";
import logo from "../../assets/logo.png";
import { getDemo } from "../../api/demo.api";
import { Oval } from "react-loader-spinner";
import { generateNoise } from "../../utils/noiseGen";
import warpNoise from "../../utils/warpNoise";




export const Landing = () => {
  const [websiteURL, setWebsiteURL] = useState("");
  const [billData, setBillData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [didGetExample, setDidGetExample] = useState(false);
  const containerRef = useRef(null);

  // Function to format the date
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  useEffect(() => {
    const width = 256;
    const height = 256;
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    let offset = 0;
    function animate() {
      offset += 0.002;
      const noise = generateNoise(width, height, 40, 8, 0.6, 2);
      const warpedNoise = warpNoise(noise, 20 + Math.sin(offset) * 10);

      if (ctx) {
        const imageData = ctx.createImageData(width, height);
        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            const i = (y * width + x) * 4;
            const value = Math.floor(warpedNoise[y][x] * 255);
            imageData.data[i] = value;
            imageData.data[i + 1] = value;
            imageData.data[i + 2] = value;
            imageData.data[i + 3] = 128;
          }
        }
        ctx.putImageData(imageData, 0, 0);
      }

      const dataURL = canvas.toDataURL();
      if (containerRef.current) {
        containerRef.current.style.backgroundImage = `linear-gradient(rgba(235, 225, 209, 0.9), rgba(235, 225, 209, 0.9)), url(${dataURL})`;
        containerRef.current.style.backgroundSize = "cover";
      }

      requestAnimationFrame(animate);
    }

    animate();

    return () => {
      cancelAnimationFrame(animate);
    };
  }, []);

  useEffect(() => {
    const width = 256;
    const height = 256;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
  
    let offset = 0;
    function animate() {
      offset += 0.002;
      const noise = generateNoise(width, height, 40, 8, 0.6, 2);
      const warpedNoise = warpNoise(noise, 20 + Math.sin(offset) * 10);
      
      if (ctx) {
        const imageData = ctx.createImageData(width, height);
        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            const i = (y * width + x) * 4;
            const value = Math.floor(warpedNoise[y][x] * 255);
            imageData.data[i] = value;
            imageData.data[i + 1] = value;
            imageData.data[i + 2] = value;
            imageData.data[i + 3] = 128; 
          }
        }
        ctx.putImageData(imageData, 0, 0);
      }
  
      const dataURL = canvas.toDataURL();
      if (containerRef.current) {
        containerRef.current.style.backgroundImage = `linear-gradient(rgba(235, 225, 209, 0.9), rgba(235, 225, 209, 0.9)), url(${dataURL})`;
        containerRef.current.style.backgroundSize = 'cover';
      }
  
      requestAnimationFrame(animate);
    }
  
    animate();
  
    return () => {
      cancelAnimationFrame(animate);
    };
  }, []);
  
  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.navbar}>
        <img src={logo} className={styles.logo} alt="We Sifted Logo"/>
        <button className={styles.signUpButton}>make it yours!</button>
      </div>
      <div className={styles.content}>
      <div className={styles.leftContent}>
          <h2 className={styles.subtitle}>
            legislations are <span className={styles.italics}>fickle</span>.{" "}
            <span className={styles.golden}>
              regulations are <span className={styles.italics}>fiddly</span>.
            </span>
          </h2>
          <h1 className={styles.title}>
            you shouldn't <span className={styles.italics}>have</span> to worry.
          </h1>
          <p className={styles.description}>
            get weekly <span className={styles.bold}>curated bill updates</span>{" "}
            <span className={styles.italics}>(that matter)</span> in your inbox,
            tailored to <span className={styles.bold}>your profile,</span>{" "}
            without the overwhelm.{" "}
          </p>
          <div className={styles.inputContainer}>
            <input
              type="text"
              className={styles.websiteInput}
              placeholder="enter your company website"
              value={websiteURL}
              onChange={(e) => setWebsiteURL(e.target.value)}
            />
            <button
              className={styles.websiteInputButton}
              onClick={async () => {
                if (websiteURL && websiteURL.trim() !== "") {
                  const formattedURL =
                    websiteURL.startsWith("http://") ||
                    websiteURL.startsWith("https://")
                      ? websiteURL
                      : `https://${websiteURL}`;
                  setIsLoading(true);
                  setWebsiteURL("");
                  const { number, summary, title, splits, updateDate } =
                    await getDemo(formattedURL);
                  setBillData({ number, summary, title, splits, updateDate });
                  setIsLoading(false);
                  setDidGetExample(true);
                }
              }}
            >
              try it!
            </button>
          </div>
          <p className={styles.ps}>p.s. we’ve got more things in the back</p>
        </div>
        <div className={styles.rightContent}>
          <h1 className={styles.rightContentTitle}>
            {isLoading
              ? "getting your bill..."
              : didGetExample
              ? "so, we sifted this..."
              : "here's an example..."}
          </h1>
          {!didGetExample && !isLoading && (
            <p className={styles.demoText}>
              Want to get your own custom bill? Enter your company website on
              the left.
            </p>
          )}
          {isLoading ? (
            <div className={styles.loadingBill}>
              <Oval
                color="#947D47"
                secondaryColor="#5F5747"
                height={150}
                width={150}
              />
            </div>
          ) : (
            <div className={styles.bill}>
              <div className={styles.upperBillBar}>
                <h3 className={styles.upperBillBarText}>
                  #{didGetExample ? billData.number : "5061"}
                </h3>
                <h3 className={styles.upperBillBarText}>
                  {didGetExample
                    ? formatDate(billData.updateDate)
                    : "Sep 23, 2024"}
                </h3>
              </div>
              <h2 className={styles.billTitle}>
                {didGetExample
                  ? billData.title
                  : "21st Century Workforce Partnerships Act"}
              </h2>
              <p>
                {didGetExample
                  ? billData.summary
                  : "Bill 5061 focuses on enhancing education and career training programs, particularly through partnerships between educational agencies and industry. This bill supports initiatives that provide funding for career pathways programs, including training for educators and opportunities for students to gain practical experience in coding and technology. For Codeocity, this bill could significantly impact their operations by potentially providing access to grants that support their coding courses and educational programs. It aligns with their mission to make coding education accessible and engaging, allowing them to expand their offerings and reach more students while fostering partnerships that enhance their curriculum."}
              </p>
            </div>
          )}
        </div>
      </div>

      <div className={styles.bottomContent}>
        <a href="/" className={styles.link}>
          about
        </a>
        <a href="/" className={styles.link}>
          contact
        </a>
        <a href="/" className={styles.link}>
          privacy + tos
        </a>
      </div>
    </div>
  );
};
