import React, { useState } from "react";
import styles from "./Chat.module.css";
import { FaPaperPlane } from "react-icons/fa";
import { Response } from "../response/Response.component";
//import { askQuestion } from "../../api/pdf.routes";

export const Chat = ({ onSearch }) => {
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const [chatHistory, setChatHistory] = useState([]);

  const handleSendMessage = async () => {
    try {
      setChatInput("");
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        { isUser: true, text: chatInput },
      ]);

      setIsMessageLoading(true);
      //const response = await askQuestion(chatInput, []);
      const response = "more than";
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        { isUser: false, text: response },
      ]);

      setIsMessageLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.chatContainer}>
      <div className={styles.responses}>
        {chatHistory.map((response, index) => (
          <Response
            key={index}
            isUser={response.isUser}
            text={response.text}
            onSearch={onSearch} // Pass the search handler
          />
        ))}
        {isMessageLoading && <Response isLoading={true} text="..." />}
      </div>
      <div className={styles.chatInputContainer}>
        <input
          className={styles.chatInput}
          placeholder="Enter your question"
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          onSubmit={handleSendMessage}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />
        <FaPaperPlane className={styles.sendIcon} onClick={handleSendMessage} />
      </div>
    </div>
  );
};
