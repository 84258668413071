import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../Auth.module.css"; // Reusing the same styles

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const formBody = new URLSearchParams({
      email: email,
    }).toString();

    try {
      const response = await fetch(process.env.API_URL + "/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.API_KEY}`,
        },
        body: formBody,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setSuccess(true);
      // Server should send password reset email to user
    } catch (err) {
      setError("Failed to process request. Please try again.");
      console.error("Password reset error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1 className={styles.title}>Reset Password</h1>
          {error && <div className={styles.error}>{error}</div>}

          {success ? (
            <div className={styles.successContainer}>
              <div className={styles.success}>
                Password reset instructions have been sent to your email.
              </div>
              <p className={styles.instructions}>
                Please check your inbox and follow the link to reset your
                password. The link will expire in 1 hour.
              </p>
              <Link to="/login" className={styles.button}>
                Return to Login
              </Link>
            </div>
          ) : (
            <>
              <p className={styles.instructions}>
                Enter your email address and we'll send you instructions to
                reset your password.
              </p>
              <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    required
                  />
                </div>

                <button
                  type="submit"
                  className={`${styles.button} ${
                    loading ? styles.loading : ""
                  }`}
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send Reset Instructions"}
                </button>
              </form>
            </>
          )}

          <div className={styles.forgotPasswordSignUp}>
            <Link to="/login">Back to Login</Link>
          </div>
        </div>
      </div>
    </>
  );
};
