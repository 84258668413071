import React, { useState, useRef } from "react";
import { Chat } from "./components/chat/Chat.component";
import { Pdf } from "./components/pdf/Pdf.component";
import { Worker } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import { Container, Section, Bar } from "@column-resizer/react";
import { MdDragHandle } from "react-icons/md";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import styles from "./BillChat.module.css";


export const BillChat = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const searchPluginInstance = searchPlugin();
  const searchFunctionRef = useRef(null);

  const handleSearchPluginInstance = (search) => {
    searchFunctionRef.current = search;
  };

  const handleSearch = (keyword) => {
    if (searchFunctionRef.current) {
      searchFunctionRef.current.search(keyword);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setPdfUrl(url);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <Container className={styles.container}>
        <Section minSize={400} className={styles.pdfSection}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            {pdfUrl ? (
              <div className={styles.pdfWrapper}>
                <Pdf
                  pdfUrl={pdfUrl}
                  searchPluginInstance={searchPluginInstance}
                  onSearchPluginInstance={handleSearchPluginInstance}
                />
              </div>
            ) : (
              <div className={styles.uploadContainer}>
                <div className={styles.uploadContent}>
                  <label className={styles.uploadLabel}>
                    Upload a PDF to begin
                  </label>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileUpload}
                    className={styles.fileInput}
                  />
                </div>
              </div>
            )}
          </Worker>
        </Section>

        <Bar size={8} className={styles.dragBar}>
          <MdDragHandle 
            className={styles.dragIcon}
            size={20}
          />
        </Bar>

        <Section minSize={300} className={styles.chatSection}>
          {pdfUrl ? (
            <Chat onSearch={handleSearch} />
          ) : (
            <div className={styles.chatPlaceholder}>
              <p>Upload a PDF document to start chatting</p>
            </div>
          )}
        </Section>
      </Container>
    </div>
  );
};

