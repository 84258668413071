import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../Auth.module.css";

export const CreateOrganizationUrl = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const industryOptions = [
    { label: "CASH GRAINS AND OTHER CROPS", value: "011" },
    { label: "FIELD CROPS, EXCEPT CASH GRAINS", value: "013" },
    { label: "VEGETABLES AND MELONS", value: "016" },
    { label: "FRUITS AND TREE NUTS", value: "017" },
    { label: "HORTICULTURAL SPECIALTIES", value: "018" },
    {
      label: "LIVESTOCK, EXCEPT DAIRY, POULTRY AND ANIMAL SPECIALTIES",
      value: "021",
    },
    { label: "DAIRY CATTLE FOR BREEDING, LIVE", value: "024" },
    { label: "POULTRY AND EGGS", value: "025" },
    { label: "ANIMAL SPECIALTIES", value: "027" },
    { label: "TIMBER TRACTS", value: "081" },
    { label: "FORESTRY PRODUCTS, NSPF", value: "083" },
    { label: "FORESTRY PRODUCTS, NSPF", value: "08X" },
    {
      label:
        "FISH, FRESH OR CHILLED, EXCEPT PACKAGED; AND MISCELLANEOUS MARINE PRODUCTS, NSPF",
      value: "091",
    },
    { label: "HUNTING AND TRAPPING, AND GAME PROPAGATION", value: "097" },
    { label: "IRON ORE", value: "101" },
    { label: "COPPER ORE", value: "102" },
    { label: "LEAD AND ZINC ORE", value: "103" },
    { label: "PRECIOUS METAL ORES, INCLUDING GOLD CONTENT", value: "104" },
    { label: "BAUXITE", value: "105" },
    { label: "FERROALLOY ORES, NSPF", value: "106" },
    { label: "METALLIC ORES, NSPF", value: "109" },
    { label: "ANTHRACITE COAL", value: "111" },
    { label: "BITUMINOUS COAL AND LIGNITE", value: "121" },
    { label: "BITUMINOUS COAL AND LIGNITE", value: "122" },
    { label: "ANTHRACITE COAL", value: "123" },
    { label: "CRUDE PETROLEUM AND NATURAL GAS", value: "131" },
    { label: "NATURAL GAS LIQUIDS", value: "132" },
    {
      label:
        "GRANITE, LIMESTONE, ETC SUITABLE FOR MONUMENTAL, PAVING, ETC AND MARBLE ROUGH AND SQUARED",
      value: "141",
    },
    { label: "CRUSHED OR BROKEN STONE, INCLUDING RIPRAP", value: "142" },
    { label: "SAND AND GRAVEL", value: "144" },
    { label: "CLAY, CERAMIC, AND REFACTORY MINERALS", value: "145" },
    { label: "CHEMICAL AND FERTILIZER MINERALS", value: "147" },
    { label: "NONMETALLIC MINERALS, NSPF, EXCEPT FUELS", value: "149" },
    { label: "MEAT PRODUCTS AND MEAT PACKING PRODUCTS", value: "201" },
    { label: "DAIRY PRODUCTS", value: "202" },
    {
      label: "CANNED AND PRESERVED SEAFOOD, FRUITS, VEGETABLES, JAMS, ETC",
      value: "203",
    },
    { label: "GRAIN MILL PRODUCTS", value: "204" },
    {
      label:
        "BAKERY PRODUCTS, EXCEPT FROZEN, EXCLUDING SEALING WAFERS, COMMUNION WAFERS, AND SIMILAR PRODUCTS",
      value: "205",
    },
    { label: "SUGAR AND CONFECTIONERY PRODUCTS", value: "206" },
    { label: "FATS AND OILS", value: "207" },
    { label: "BEVERAGES AND FLAVORING EXTRACTS", value: "208" },
    { label: "FOOD PREPARATIONS AND RELATED PRODUCTS, NSPF", value: "209" },
    { label: "CIGARETTES", value: "211" },
    { label: "CIGARS, CHEROOTS, AND CIGARILLOS", value: "212" },
    { label: "TOBACCO AND SNUFF", value: "213" },
    {
      label: "MANUFACTURED TOBACCO, NSPF, INCLUDING PROCESSED SHEET TOBACCO",
      value: "214",
    },
    { label: "BROAD WOVEN FABRICS, COTTON", value: "221" },
    { label: "BROAD WOVEN FABRICS, MANMADE FIBERS AND SILK", value: "222" },
    { label: "BROAD WOVEN FABRICS, WOOL", value: "223" },
    { label: "NARROW WOVEN FABRICS", value: "224" },
    { label: "HOSIERY AND KNIT FABRICS", value: "225" },
    { label: "FLOOR COVERINGS", value: "227" },
    { label: "YARN AND THREAD, TEXTILE FIBERS", value: "228" },
    { label: "TEXTILE GOODS, NSPF", value: "229" },
    {
      label: "SUITS AND COATS, MEN'S AND BOYS', EXCEPT RAINCOATS",
      value: "231",
    },
    {
      label:
        "SHIRTS, NIGHTWEAR, UNDERWEAR, TROUSERS, AND WORK CLOTHING, MEN'S AND BOYS",
      value: "232",
    },
    {
      label:
        "BLOUSES, WAISTS, DRESSES, SUITS, COATS, AND SKIRTS, WOMEN'S AND MISSES' NEW, NOT KNIT OR CROCHETED",
      value: "233",
    },
    {
      label:
        "NIGHTWEAR AND UNDERWEAR, WOMEN'S, GIRLS', CHILDRENS' AND INFANTS'; CORSETS AND ALLIED GARMENTS",
      value: "234",
    },
    { label: "HEADWEAR, EXCEPT RUBBER OR PLASTIC", value: "235" },
    { label: "OUTERWEAR, NSPF, TEXTILE FIBERS", value: "236" },
    {
      label: "FUR CLOTHING AND OTHER ARTICLES MADE OF FURSKINS, NSPF",
      value: "237",
    },
    { label: "WEARING APPAREL AND ACCESSORIES, NSPF", value: "238" },
    { label: "FABRICATED TEXTILE ARTICLES, NSPF", value: "239" },
    {
      label:
        "MILITARY APPAREL OF ALL TYPES AND MATERIALS, INCLUDING HEADWEAR AND FOOTWEAR",
      value: "23X",
    },
    { label: "LOGS, PULPWOOD, UTILITY LINE POLES, PILING ETC", value: "241" },
    { label: "SAWMILL AND PLANING MILL PRODUCTS", value: "242" },
    {
      label: "MILLWORK, VENEER, PLYWOOD, AND STRUCTURAL WOOD PRODUCTS",
      value: "243",
    },
    { label: "WOODEN CONTAINERS", value: "244" },
    { label: "WOOD BUILDINGS AND MOBILE HOMES", value: "245" },
    { label: "MISCELLANEOUS WOOD PRODUCTS", value: "249" },
    { label: "HOUSEHOLD FURNITURE", value: "251" },
    { label: "OFFICE FURNITURE", value: "252" },
    {
      label: "PARTITIONS, SHELVING, LOCKERS, AND OFFICE AND STORE FIXTURES",
      value: "254",
    },
    {
      label:
        "VENETIAN BLINDS AND PARTS, IRON, STEEL OR ALUMINUM; FURNITURE, NSPF",
      value: "259",
    },
    { label: "PULP MILL PRODUCTS", value: "261" },
    { label: "PAPER MILL PRODUCTS", value: "262" },
    { label: "PAPERBOARD MILL PRODUCTS", value: "263" },
    {
      label:
        "CONVERTED PAPER AND PAPERBOARD PRODUCTS, EXCEPT CONTAINERS AND BOXES",
      value: "264",
    },
    { label: "PAPERBOARD CONTAINERS AND BOXES", value: "265" },
    { label: "BUILDING PAPER AND BOARD", value: "266" },
    {
      label:
        "CONVERTED PAPER AND PAPERBOARD PRODUCTS, EXCEPT CONTAINERS AND BOXES",
      value: "267",
    },
    { label: "NEWSPAPERS, UNBOUND, EXCEPT AS WASTE", value: "271" },
    { label: "PERIODICALS, UNBOUND, EXCEPT AS WASTE", value: "272" },
    { label: "BOOKS AND PAMPHLETS", value: "273" },
    { label: "MISCELLANEOUS PUBLICATIONS", value: "274" },
    { label: "COMMERCIAL PRINTED MATTER", value: "275" },
    {
      label: "MANIFOLD BUSINESS FORMS AND INTERLEAVED CARBON SETS",
      value: "276",
    },
    { label: "GREETING CARDS", value: "277" },
    { label: "BLANKBOOKS, LOOSE LEAF BINDERS AND DEVICES", value: "278" },
    {
      label:
        "PRINTING BLOCKS, CYLINDERS, PLATES, SHEETS AND STEREOTYPES, ENGRAVED, ETCHED, ETC, OTHER THAN FOR TEXTILES, LEATHER, ETC",
      value: "279",
    },
    { label: "INDUSTRIAL INORGANIC CHEMICALS", value: "281" },
    {
      label:
        "PLASTICS MATERIALS AND SYNTHETIC RESINS; SYNTHETIC RUBBER; SYNTHETIC AND OTHER MANMADE FIBERS, EXCEPT GLASS",
      value: "282",
    },
    { label: "DRUGS", value: "283" },
    {
      label:
        "SOAPS, DETERGENTS, AND CLEANING PREPARATIONS; PERFUMES, COSMETICS, AND OTHER TOILET PREPARATIONS",
      value: "284",
    },
    { label: "PAINTS, VARNISHES, LACQUERS, AND ENAMELS", value: "285" },
    { label: "INDUSTRIAL ORGANIC CHEMICALS", value: "286" },
    { label: "AGRICULTURAL CHEMICALS", value: "287" },
    { label: "MISCELLANEOUS CHEMICALS", value: "289" },
    { label: "PETROLEUM REFINERY PRODUCTS", value: "291" },
    { label: "PAVING AND ROOFING MATERIALS", value: "295" },
    { label: "PETROLEUM AND COAL PRODUCTS, NSPF", value: "299" },
    { label: "TIRES AND INNER TUBES", value: "301" },
    { label: "RUBBER AND PLASTIC FOOTWEAR", value: "302" },
    { label: "RECLAIMED RUBBER", value: "303" },
    { label: "RUBBER AND PLASTIC HOSE AND BELTING", value: "304" },
    { label: "RUBBER AND PLASTICS HOSE AND BELTING", value: "305" },
    { label: "FABRICATED RUBBER PRODUCTS, NSPF", value: "306" },
    { label: "MISCELLANEOUS PLASTIC PRODUCTS", value: "307" },
    { label: "MISCELLANEOUS PLASTICS PRODUCTS", value: "308" },
    { label: "LEATHER, TANNED OR FINISHED", value: "311" },
    {
      label:
        "PREPARED PARTS OF FOOTWEAR, ALL MATERIALS OTHER THAN RUBBER, ELASTOMER RESIN, METAL, AND ASBESTOS",
      value: "313",
    },
    { label: "FOOTWEAR, EXCEPT RUBBER AND PLASTIC", value: "314" },
    { label: "LEATHER GLOVES, EXCEPT SPORT GLOVES", value: "315" },
    { label: "LUGGAGE AND RELATED ITEMS", value: "316" },
    { label: "HANDBAGS AND OTHER PERSONAL LEATHER GOODS", value: "317" },
    { label: "LEATHER GOODS, NSPF", value: "319" },
    { label: "FLAT GLASS", value: "321" },
    {
      label: "GLASS CONTAINERS; PRESSED AND BLOWN GLASS AND GLASSWARE",
      value: "322",
    },
    { label: "PRODUCTS OF PURCHASED GLASS, NSPF", value: "323" },
    { label: "CEMENT, HYDRAULIC", value: "324" },
    { label: "STRUCTURAL CLAY PRODUCTS", value: "325" },
    { label: "POTTERY AND RELATED PRODUCTS", value: "326" },
    { label: "CONCRETE, GYPSUM AND PLASTER PRODUCTS, AND LIME", value: "327" },
    { label: "CUT STONE AND STONE PRODUCTS", value: "328" },
    {
      label:
        "ABRASIVE, ASBESTOS, AND MISCELLANEOUS NONMETALLIC MINERAL PRODUCTS",
      value: "329",
    },
    {
      label:
        "BLAST FURNACE, STEEL WORKS, ROLLING MILL, AND FINISHING MILL PRODUCTS",
      value: "331",
    },
    { label: "IRON AND STEEL PRODUCTS", value: "332" },
    { label: "SMELTER AND REFINED NONFERROUS METAL", value: "333" },
    {
      label: "SECONDARY SMELTING AND REFINING OF NONFERROUS METALS",
      value: "334",
    },
    { label: "ROLLED, DRAWN, AND EXTRUDED NONFERROUS METAL", value: "335" },
    { label: "NONFERROUS METAL CASTINGS AND FORGINGS", value: "336" },
    { label: "PRIMARY METAL PRODUCTS, NSPF", value: "339" },
    {
      label: "CANS, USED FOR TRANSPORT OF GOODS, OF IRON, STEEL, OR ALUMINUM",
      value: "341",
    },
    { label: "CUTLERY, HAND TOOLS, AND HARDWARE, NSPF", value: "342" },
    {
      label:
        "HEATING EQUIPMENT, EXCEPT ELECTRIC AND WARM AIR; AND PLUMBING FIXTURES",
      value: "343",
    },
    { label: "FABRICATED STRUCTURAL METAL PRODUCTS", value: "344" },
    {
      label:
        "BOLTS, NUTS, SCREWS, RIVETS, WASHERS AND SIMILAR ARTICLES OF BASE METALS",
      value: "345",
    },
    { label: "METAL FORGINGS AND STAMPINGS", value: "346" },
    {
      label: "ORDNANCE AND ACCESSORIES, EXCEPT VEHICLES AND GUIDED MISSILES",
      value: "348",
    },
    { label: "FABRICATED METAL PRODUCTS, NSPF", value: "349" },
    {
      label: "ENGINES AND TURBINES, AND PARTS AND ACCESSORIES, NSPF",
      value: "351",
    },
    {
      label:
        "FARM AND GARDEN MACHINERY AND EQUIPMENT, AND PARTS AND ATTACHMENTS, NSPF",
      value: "352",
    },
    {
      label: "CONSTRUCTION, MINING, AND MATERIALS HANDLING MACHINERY",
      value: "353",
    },
    {
      label:
        "METALWORKING MACHINES AND EQUIPMENT, AND PARTS, ACCESSORIES AND ATTACHMENTS, NSPF",
      value: "354",
    },
    {
      label:
        "SPECIAL INDUSTRY MACHINES AND EQUIPMENT, NSPF, AND PARTS, ACCESSORIES AND ATTACHMENTS, NSPF",
      value: "355",
    },
    {
      label:
        "GENERAL INDUSTRIAL MACHINES AND EQUIPMENT, NSPF, AND PARTS AND ATTACHMENTS, NSPF",
      value: "356",
    },
    {
      label:
        "OFFICE, COMPUTING, AND ACCOUNTING MACHINES, AND PARTS AND ACCESSORIES, NSPF",
      value: "357",
    },
    {
      label:
        "REFRIGERATION AND SERVICE MACHINERY, AND PARTS AND ATTACHMENTS, NSPF",
      value: "358",
    },
    {
      label:
        "FLEXIBLE TUBING AND PIPING OF BASE METAL; AND MACHINE PARTS, NSPF, NONELECTRIC, NSPF",
      value: "359",
    },
    {
      label:
        "ELECTRIC TRANSMISSION AND DISTRIBUTION EQUIPMENT, AND PARTS, NSPF",
      value: "361",
    },
    { label: "ELECTRICAL INDUSTRIAL APPARATUS", value: "362" },
    { label: "HOUSEHOLD APPLIANCES, AND PARTS, NSPF", value: "363" },
    { label: "ELECTRIC LIGHTING AND WIRING EQUIPMENT", value: "364" },
    {
      label:
        "RADIO AND TV RECEIVING SETS; PHONOGRAPHS; RECORDERS; MICROPHONES; LOUDSPEAKERS; AUDIO AMPLIFIERS; & OTHER AUDIO EQUIPMENT & ACCESSORIES",
      value: "365",
    },
    { label: "COMMUNICATION EQUIPMENT AND APPARATUS", value: "366" },
    { label: "ELECTRONIC COMPONENTS AND ACCESSORIES", value: "367" },
    { label: "ELECTRICAL MACHINERY, APPARATUS, AND PARTS, NSPF", value: "369" },
    {
      label: "MOTOR VEHICLES AND MOTOR VEHICLE EQUIPMENT, AND PARTS, NSPF",
      value: "371",
    },
    { label: "AIRCRAFT AND PARTS, NSPF", value: "372" },
    { label: "SHIP AND BOAT BUILDING AND REPAIRING", value: "373" },
    { label: "RAILROAD EQUIPMENT", value: "374" },
    {
      label:
        "MOTORCYCLES, MOTOR SCOOTERS, MOTORBIKES, AND CYCLES, NOT MOTORIZED, NSPF, AND PARTS, NSPF",
      value: "375",
    },
    {
      label: "GUIDED MISSILES AND SPACE VEHICLES, AND PARTS, NSPF",
      value: "376",
    },
    {
      label: "MISCELLANEOUS TRANSPORTATION EQUIPMENT, AND PARTS, NSPF",
      value: "379",
    },
    {
      label:
        "AIRCRAFT FLIGHT, NAUTICAL & NAVIGATIONAL, LABORATORY & SCIENTIFIC, GEOPHYSICAL, SURVEYING & DRAFTING INSTRUMENTS, & PARTS, NSPF",
      value: "381",
    },
    {
      label:
        "INSTRUMENTS FOR MEASURING, DETECTING, TESTING, AND/OR CONTROLLING NONELECTRIC QUANTITIES, NSPF, AND PARTS & ACCESSORIES, NSPF",
      value: "382",
    },
    {
      label:
        "OPTICAL INSTRUMENTS, ELEMENTS, LENSES, AND PARTS AND ACCESSORIES, NSPF",
      value: "383",
    },
    {
      label: "SURGICAL, MEDICAL, AND DENTAL INSTRUMENTS AND SUPPLIES",
      value: "384",
    },
    {
      label:
        "OPTHALMIC FOCUS LENSES, UNMOUNTED, INCLUDING CONTACT LENSES; AND SPECTACLES, AND PARTS, NSPF",
      value: "385",
    },
    { label: "PHOTOGRAPHIC EQUIPMENT AND SUPPLIES", value: "386" },
    {
      label: "WATCHES, CLOCKS, CLOCKWORK OPERATED DEVICES, AND PARTS, NSPF",
      value: "387",
    },
    {
      label:
        "JEWELRY OF PRECIOUS METAL; JEWELRY FINDINGS; PRECIOUS AND SEMIPRECIOUS STONES, NOT SET OR STRUNG; SILVERWARE AND PLATED WARE",
      value: "391",
    },
    {
      label: "MUSICAL INSTRUMENTS, AND PARTS AND ACCESSORIES, NSPF",
      value: "393",
    },
    {
      label:
        "TOYS AND SPORTING, ATHLETIC, AND GYMNASTIC GOODS, APPLIANCES, APPARATUS OR ACCESSORIES, NSPF",
      value: "394",
    },
    {
      label:
        "PENS, PENCILS, AND OTHER OFFICE AND STATIONERY SUPPLIES, AND ARTISTS' MATERIALS",
      value: "395",
    },
    {
      label: "JEWELRY, NSPF; BUTTONS, NEEDLES, AND MISCELLANEOUS NOTIONS",
      value: "396",
    },
    {
      label:
        "BROOMS AND BRUSHES; LINOLEUM AND OTHER FLOOR COVERINGS WITH A TEXTILE BASE; MATCHES; CANDLES, TAPERS AND SIMILAR ITEMS",
      value: "399",
    },
    { label: "MANUFACTURED COMMODITIES NOT IDENTIFIED BY KIND", value: "3XX" },
    { label: "UNDOCUMENTED EXPORTS TO CANADA", value: "900" },
    { label: "SCRAP AND WASTE", value: "910" },
    { label: "USED OR SECOND-HAND MERCHANDISE", value: "920" },
    {
      label:
        "GOODS IMPORTED FROM CANADA AND RETURNED TO CANADA WITHOUT HAVING BEEN ADVANCED IN VALUE OR IMPROVED IN CONDITION OR COMBINED WITH OTHER ARTICLES",
      value: "980",
    },
    { label: "SPECIAL CLASSIFICATION PROVISIONS, NSPF", value: "990" },
  ];

  const teamSizeOptions = ["1-10", "10-50", "50-100", "100-500", "500+"];
  const marketCapOptions = [
    "0-10 Million $",
    "10-15 Million $",
    "15-300 Million $",
    "300 MIllion - 2 Billion $",
    "2 Billion + $",
  ];

  const [formData, setFormData] = useState({
    firmName: "",
    websiteUrl: "",
    teamSize: "",
    industrySector: "",
    marketCap: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Set the website URL from navigation state when component mounts
  useEffect(() => {
    if (location.state?.websiteUrl) {
      setFormData((prev) => ({
        ...prev,
        websiteUrl: location.state.websiteUrl,
        description: location.state.websiteUrl, // Set description as website URL
      }));
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    // Validate website URL
    try {
      new URL(formData.websiteUrl);
    } catch (err) {
      setError("Please enter a valid website URL");
      setLoading(false);
      return;
    }
    // Create URLSearchParams object for form data
    const formBody = new URLSearchParams({
      name: formData.firmName,
      size: formData.teamSize,
      naics_code: formData.industrySector,
      market_cap: formData.marketCap,
      description: formData.websiteUrl,
    }).toString();
    console.log(formBody);

    try {
      const response = await fetch(process.env.API_URL + "/organizations", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.API_KEY}`,
        },
        body: formBody,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSuccess(true);
    } catch (err) {
      setError("Failed to create organization. Please try again.");
      console.error("Organization creation error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.whiteContainer}>
        <div className={styles.whiteCard}>
          <h1 className={styles.title}>Create Organization</h1>
          {error && <div className={styles.error}>{error}</div>}
          {success && (
            <div className={styles.success}>
              Organization created successfully!
            </div>
          )}

          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <input
                type="text"
                id="firmName"
                name="firmName"
                value={formData.firmName}
                onChange={handleChange}
                placeholder="Firm Name"
                required
              />
            </div>

            <div className={styles.formGroupRow}>
              <div className={styles.formGroup}>
                <select
                  id="teamSize"
                  name="teamSize"
                  value={formData.teamSize}
                  onChange={handleChange}
                  required
                  className={!formData.teamSize ? styles.placeholder : ""}
                >
                  <option value="" disabled>
                    Select Team Size
                  </option>
                  {teamSizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>

              <div className={styles.formGroup}>
                <select
                  id="marketCap"
                  name="marketCap"
                  value={formData.marketCap}
                  onChange={handleChange}
                  required
                  className={!formData.marketCap ? styles.placeholder : ""}
                >
                  <option value="" disabled>
                    Select Market Cap
                  </option>
                  {marketCapOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={styles.formGroup}>
              <select
                id="industrySector"
                name="industrySector"
                value={formData.industrySector}
                onChange={handleChange}
                required
                className={!formData.industrySector ? styles.placeholder : ""}
              >
                <option value="" disabled>
                  Select Industry Sector
                </option>
                {industryOptions.map((industry) => (
                  <option key={industry.value} value={industry.value}>
                    {industry.label}
                  </option>
                ))}
              </select>
            </div>

            <button
              type="submit"
              className={`${styles.button} ${loading ? styles.loading : ""}`}
              disabled={loading}
            >
              {loading ? "Creating organization..." : "Get Started!"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
