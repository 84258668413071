function generateNoise(
  width,
  height,
  scale = 20,
  octaves = 8,
  persistence = 0.5,
  lacunarity = 2
) {
  const noise = [];
  for (let y = 0; y < height; y++) {
    noise[y] = [];
    for (let x = 0; x < width; x++) {
      let amplitude = 1;
      let frequency = 1;
      let noiseHeight = 0;

      for (let i = 0; i < octaves; i++) {
        const sampleX = (x / scale) * frequency;
        const sampleY = (y / scale) * frequency;

        // Use a smoother noise function
        const perlinValue = smoothNoise(sampleX, sampleY);
        noiseHeight += perlinValue * amplitude;

        amplitude *= persistence;
        frequency *= lacunarity;
      }

      noise[y][x] = (noiseHeight + 1) / 2; // Normalize to 0-1
    }
  }
  return noise;
}

function smoothNoise(x, y) {
  const corners =
    (noise2D(x - 1, y - 1) +
      noise2D(x + 1, y - 1) +
      noise2D(x - 1, y + 1) +
      noise2D(x + 1, y + 1)) /
    16;
  const sides =
    (noise2D(x - 1, y) +
      noise2D(x + 1, y) +
      noise2D(x, y - 1) +
      noise2D(x, y + 1)) /
    8;
  const center = noise2D(x, y) / 4;
  return corners + sides + center;
}

function noise2D(x, y) {
  const n = x + y * 57;
  let value = (n << 13) ^ n;
  return (
    1.0 -
    ((value * (value * value * 15731 + 789221) + 1376312589) & 0x7fffffff) /
      1073741824.0
  );
}

export { generateNoise };
