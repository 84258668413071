import React from "react";
import { FaUserAlt, FaBook, FaSearch } from "react-icons/fa";

export const Response = ({ isUser, text, isLoading, onSearch }) => {
  const handleSearchClick = () => {
    if (onSearch) {
      onSearch("test search");
    }
  };

  return (
    <div
      className="flex items-start gap-4 p-4 w-full"
      style={{
        alignItems: isLoading ? "center" : "flex-start",
      }}
    >
      {isUser ? (
        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200">
          <FaUserAlt className="w-4 h-4 text-gray-700" />
        </div>
      ) : (
        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-600">
          <FaBook className="w-4 h-4 text-white" />
        </div>
      )}

      {isLoading ? (
        <div className="flex items-center justify-center w-8 h-8">
          <div className="w-6 h-6 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
        </div>
      ) : isUser ? (
        <p className="text-gray-800">{text}</p>
      ) : (
        <div className="flex-1">
          <h3 className="font-semibold mb-2">Answer:</h3>
          <p className="text-gray-800 mb-4">{text}</p>
          <div className="flex items-center gap-4">
            <div className="flex gap-2">
              <span className="px-2 py-1 bg-gray-100 rounded text-sm">
                p. 312
              </span>
              <span className="px-2 py-1 bg-gray-100 rounded text-sm">
                p. 642
              </span>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};
