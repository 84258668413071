import React from "react";
import styles from "./BillRow.module.css";
import { HiDotsVertical } from "react-icons/hi";

export const BillRow = ({ bill }) => {
  const formatImpact = (impact) => {
    return (impact * 100).toFixed(0) + "%";
  };
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.id}>
        <span className={styles.idBadge}>{bill.id}</span>
      </div>
      <div className={styles.title}>{bill.title}</div>
      <div className={styles.summary}>{bill.summary}</div>
      <div className={styles.lastActionDate}>
        {formatDate(bill.lastActionDate)}
      </div>
    </div>
  );
};
